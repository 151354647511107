import React, {useContext} from 'react'
import {MyContext} from '../contexts/MyContext'

// Importing the Login & Register Componet
import Login from './Login'
import Register from './Register'
import Header from './../components/Header';
import Footer from './../components/Footer';
import Sidebar from './../components/Sidebar';
import Content from './../components/Content';


function Home(){

    const {rootState} = useContext(MyContext);
    const {isAuth,showLogin} = rootState;

    // If user Logged in
    if(isAuth)
    {
        return(
            <div className="wrapper">
                
                <Header />
                <Sidebar />
                <div className="content-wrapper" style={{minHeight: '960px'}}>
                    <div className="container-fluid">
                    <Content />
                    </div>
                </div>
                <Footer />
                
                
            
            </div>
        )
    }
    // Showing Login Or Register Page According to the condition
    else if(showLogin){
        
        return <Login/>;
    }
    else{
        return <Register/>;
    }
    
}

export default Home;