import React, { useContext, useState, useEffect } from 'react';
import { MyContext } from '../contexts/MyContext'
import Login from './Login'
import Register from './Register'
import Header from './../components/Header';
import Footer from './../components/Footer';
import Sidebar from './../components/Sidebar';
import DataTable from 'react-data-table-component';
import './../assets/invoicePrint.css';
import AddInstallments from './AddInstallment';
import SingleRecipt from './SingleRecipt';
import MUIDataTable from "mui-datatables";
import Updatesale from './Updatesale';
import swal from 'sweetalert2';
import Updateinstallment from './Updateinstallment';

function Listallsales(props) {

  const { rootState, getAllSales, getInstallmetntsBySaleId,DeleteSale,DeleteInstallment } = useContext(MyContext);
  const { isAuth, showLogin } = rootState;
  let [rows, setRows] = useState([]);
  let [_viewInstallmentsForModal, setViewForModal] = useState([]);
  let [_ReciptData, SetReciptData] = useState([]);
  let [_AddInstallmentsForModal, setAddForModal] = useState([]);
  let [_UpdateSaleMode,setUpdate]=useState([]);
  let [_InstallmentUpdateSaleMode,setInstallmentUpdate]=useState([]);
  

  const Userdata = async () => {
    const data = await getAllSales();
    let _trows = [];
    if(data.message!=="No records found in database!")
    for (const [index, value] of data.entries()) {
      _trows.push(value);
    }
    setRows(_trows);
  };

  useEffect(()=>{
        
    Userdata();
  },[]);
  


  if (isAuth) {

  const UpdateSaleItem=(id)=>{
    setUpdate("");
    setInstallmentUpdate("");
    setAddForModal("");   
    SetReciptData("");
    setViewForModal("");
    setUpdate(<Updatesale id={id} />);
  }

  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: {
        display: false,
      }
    },
    {
      label: 'Property Type',
      name: 'propertyType'
    },
    {
      label: 'File Number',
      name: 'fileNumber'
    },
    {
      label: 'Sales Manager',
      name: 'salesManager'
    },

    {
      label: 'Customer Name',
      name: 'custName'
    },
    {
      label: 'Plot Size',
      name: 'plotSize'

    },
    {
      label: 'Plot Location',
      name: 'plot'
    },
    {
      label: 'Total Sale Price',
      name: 'salePrice'

    },
    {
      label: 'Sale Date',
      name: 'saledate'

    },
    {
      label: 'Payment Period',
      name: 'paymentPeriod',
      options: {
        display: false,
      }
    },
    {
      label: 'Installment Type',
      name: 'installmentType',
      options: {
        display: false,
      }

    },
    {
      label:'Clearance',
      name:'cleared',
      options: {
        display: false,
      }
    },
    {
      label:'Installments Paid',
      name:'paidInstallments',
      options: {
        display: false,
      }
    },
    {
      name: "Action",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{display:"inline-grid"}}>
            <button onClick={() => viewInstallments(tableMeta.rowData[0]) } className="btn btn-outline-primary btn-sm">
              View Installments
            </button>
            <button onClick={() => UpdateSaleItem(tableMeta.rowData[0]) } className="btn btn-outline-primary btn-sm">
              EDIT
            </button>
            {tableMeta.rowData[11]==='No'?<button onClick={() => addInstallments(tableMeta.rowData[0]) } className="btn btn-outline-success btn-sm">Add Installments
            </button>:null}
            
            {parseInt(tableMeta.rowData[12])===0?<button onClick={() => DeleteSal(tableMeta.rowData[0]) } className="btn btn-outline-danger btn-sm">Delete
            </button>:null}
            </div>
          );
        }
      }
    }
  ];

  //#region View Installments 
  

  const viewInstallments = async (id) => {
    SetReciptData("");
    setAddForModal("");
    setInstallmentUpdate("");
    setUpdate("");
    const data = await getInstallmetntsBySaleId(id);
    const viewColumns = [      
    {
      name: 'Invoice ID',
      selector: 'invoiceId',
      sortable: true
    },
      {
        name: 'Customer Name',
        selector: 'custName',
        sortable: true
      },
      
      {
        name: 'Installment Number',
        selector: 'installmentNumber',
        sortable: true
      },
      

      {
        name: 'File Number',
        selector: 'fileNum',
        sortable: true
      },
      {
        name: 'Payment Date',
        selector: 'date',
        sortable: true
      },
      {
        name: 'Next Due On',
        selector: 'nextDue',
        sortable: true
      },
      {
        name: 'Installment Type',
        selector: 'type',
        sortable: true
      },
      {
        name: 'Amount',
        selector: 'amount',
        sortable: true
      },
      {
        name: 'Paid Via',
        selector: 'paidVia',
        sortable: true
      },
      {
        name: 'Action',
        sortable: false,
        cell: (row2) => 
        <span style={{display:"inline-grid"}}>
          <button onClick={() => printRecipt(row2)} id={row2.id} name="view" className="btn btn-outline-success btn-sm">View Recipt</button>
          <button onClick={() => updateInstallment(row2)} id={row2.id} name="edit" className="btn btn-outline-primary btn-sm">EDIT</button>
          <button onClick={() => deleteInstllment(row2.id)} id={row2.id} name="delete" className="btn btn-outline-danger btn-sm">DELETE</button>
        </span>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      }
    ];

    setViewForModal(
    <div className="card  no-print">
      <div className="card-body"><DataTable
        title="Installments"
        columns={viewColumns}
        data={data}
        striped={true}
        responsive={true}
        pagination={true}
      /></div>
    </div>);

  }
  //#endregion 
 

  //#region Add Installments

  const addInstallments = (id) => { 
    setAddForModal("");   
    SetReciptData("");
    setViewForModal("");
    setInstallmentUpdate("");
    setUpdate("");
    setAddForModal(()=><AddInstallments id={id} />);
  }

  const updateInstallment=(row)=>{
    setInstallmentUpdate(<Updateinstallment id={row.id} amount={row.amount} saleid={row.saleid} date={row.date} nextDue={row.nextDue} type={row.type} paidVia={row.paidVia} installmentNumber={row.installmentNumber} reciptNumber={row.reciptNumber} />);
  }

  const deleteInstllment=async(id)=>{
    await DeleteInstallment(id);
    setAddForModal("");   
    SetReciptData("");
    setViewForModal("");
    setInstallmentUpdate("");
    setUpdate("");
  }
  //#endregion 

  //#region Recipt Data

  
  const DeleteSal=async(id)=>{
    await DeleteSale(id);
    
    swal.fire({
      title: 'Success!',
      text: "Deleted!",
      icon: 'success',
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true
    });
    window.location.reload();
  }

  const printRecipt = (value) => {
    setAddForModal("");
    setInstallmentUpdate("");
    setViewForModal("");
    SetReciptData(()=><SingleRecipt installmentid={value.id} saleid={value.saleid} />);
  }
  //#endregion
  
  const options = {
    selectableRowsHideCheckboxes:true,
    searchOpen:true,
    filter: true,
    filterType: 'dropdown',
    responsive:'standard',
    selectableRowsHeader:false,
    rowsPerPageOptions:[10,15,20]
  };

  
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />

        <div className="content-wrapper" style={{ minHeight: '960px' }}>
          <section className="content">
            <div className="container-fluid">
              <MUIDataTable 
                    title={"All Sales Record"}
                    columns={columns}
                    data={rows} 
                    options={options}

                    className="no-print"
                    />
              <br/><br/>
              {_viewInstallmentsForModal}
              {_AddInstallmentsForModal}
              {_ReciptData}
              {_UpdateSaleMode}
              {_InstallmentUpdateSaleMode}
            </div>
          </section>
        </div>
        <Footer />

      </div>
    );
  }
  // Showing Login Or Register Page According to the condition
  else if (showLogin) {

    return <Login />;
  }
  else {
    return <Register />;
  }
}

export default Listallsales;