import React, {useContext,useState} from 'react'
import {MyContext} from '../contexts/MyContext'
import swal from 'sweetalert2';
import Login from './Login';
import Register from './Register';
import Header from './../components/Header';
import Footer from './../components/Footer';
import Sidebar from './../components/Sidebar';

function Addnewcustomer(){
    const {rootState,addNewCustomer} = useContext(MyContext);
    const {isAuth,showLogin} = rootState;
    const initialState = {
        userInfo:{
            name:'',
            phone:'',
            email:'',
            cnic:''
        },
        errorMsg:'',
        successMsg:'',
    }
    const [state,setState] = useState(initialState);

    if(isAuth)
    {
    // On Submit the Registration Form
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await addNewCustomer(state.userInfo);
        if(data.success){
            setState({
                ...initialState,
                successMsg:data.message,
            });
            swal.fire({
                title: 'Success!',
                text: data.message,
                icon: 'success',
                showConfirmButton: false,
                toast:true,
                position:'top-right',
                timer: 2000,
                timerProgressBar: true
              });
        }
        else{
            setState({
                ...state,
                successMsg:'',
                errorMsg:data.message
            });
            swal.fire({
                title: 'Error!',
                text: data.message,
                icon: 'error',
                confirmButtonText: 'Retry'
              });
        }
    }

    // On change the Input Value (name, email, password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value,
                errorMsg:''
            }
        });
    }
    
    return(
        
    <div className="wrapper">                    
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{minHeight: '960px'}}>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="login-box-msg">Add a new Customer</h4>

                            <form  onSubmit={submitForm} >
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="name" className="label">Customer Name: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="name"  className="form-control" required type="text" value={state.userInfo.name} onChange={onChangeValue} placeholder="Customer Name"/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-user"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="name" className="label">Customer Email: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="email" className="form-control" type="email" value={state.userInfo.email} onChange={onChangeValue} placeholder="example@example.com"/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-user-circle"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="name" className="label">Customer Phone Number: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="phone" className="form-control" type="phone" value={state.userInfo.phone} onChange={onChangeValue} placeholder="923000000000"/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-phone"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="cnic" className="label">Customer CNIC: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="cnic" className="form-control" type="text" value={state.userInfo.cnic} onChange={onChangeValue} placeholder="CNIC" pattern="[0-9]+" maxLength="13" minLength="13" />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-phone"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col-12">
                                        <button type="submit" className="btn btn-primary btn-block">Create Customer</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <Footer />
    </div>
    );
}
// Showing Login Or Register Page According to the condition
else if(showLogin){
    
    return <Login/>;
}
else{
    return <Register/>;
}
}

export default Addnewcustomer;