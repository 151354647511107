import React, { createContext,Component } from "react";
import axios from 'axios'
export const MyContext = createContext();

// Define the base URL
const Axios = axios.create({
    baseURL: 'http://fcc.faaizconstruction.com/api',
});

class MyContextProvider extends Component{
    constructor(){
        super();
        this.isLoggedIn();
    }

    // Root State
    state = {
        showLogin:true,
        isAuth:false,
        theUser:null,
    }
    
    // Toggle between Login & Signup page
    toggleNav = () => {
        const showLogin = !this.state.showLogin;
        this.setState({
            ...this.state,
            showLogin
        })
    }

    // On Click the Log out button
    logoutUser = () => {
        localStorage.removeItem('loginToken');
        this.setState({
            ...this.state,
            isAuth:false
        })
    }

    registerUser = async (user) => {

        // Sending the user registration request
        const register = await Axios.post('register.php',{
            username:user.username,
            email:user.email,
            password:user.password,
            firstname:user.firstname,
            lastname:user.lastname,
            phone:user.phone
        });

        return register.data;
    }


    loginUser = async (user) => {

        // Sending the user Login request
        const login = await Axios.post('login.php',{
            email:user.email,
            password:user.password
        });
        return login.data;
    }
    addnewsale = async (sale) =>{
        const sale_r = await Axios.post('addnewsale.php',{
            plot :sale.plot,
            customerID :sale.customerID,
            salePrice :sale.salePrice,
            saledate :sale.saledate,
            paymentPeriod :sale.paymentPeriod,
            installmentType :sale.installmentType,
            fileNumber :sale.fileNumber,
            plotSize :sale.plotSize,
            propertyType:sale.propertyType,
            salesManager:sale.salesManager
        });

        return sale_r.data;

    }
    updatesale=async(sale)=>{
        const sale_r = await Axios.post('updatesale.php',{
            sid:sale.sid,
            plot :sale.plot,
            customerID :sale.customerID,
            salePrice :sale.salePrice,
            saledate :sale.saledate,
            paymentPeriod :sale.paymentPeriod,
            installmentType :sale.installmentType,
            fileNumber :sale.fileNumber,
            plotSize :sale.plotSize,
            propertyType:sale.propertyType,
            salesManager:sale.salesManager
        });

        return sale_r.data;
    }
    updatetransfer = async (sale) =>{
        const sale_r = await Axios.post('updatetransfer.php',{
            id:sale.id,
            plot :sale.plot,
            customerID :sale.customerID,
            salePrice :sale.salePrice,
            saledate :sale.saledate,
            paymentPeriod :sale.paymentPeriod,
            installmentType :sale.installmentType,
            fileNumber :sale.fileNumber,
            plotSize :sale.plotSize,
            prev_cust:sale.prev_customerid,
            t_fee:sale.transfer_fee,
            propertyType:sale.propertyType,
            salesManager:sale.salesManager
        });

        return sale_r.data;

    }
    addnewtransfer = async (sale) =>{
        const sale_r = await Axios.post('addnewtransfer.php',{
            plot :sale.plot,
            customerID :sale.customerID,
            salePrice :sale.salePrice,
            saledate :sale.saledate,
            paymentPeriod :sale.paymentPeriod,
            installmentType :sale.installmentType,
            fileNumber :sale.fileNumber,
            plotSize :sale.plotSize,
            prev_cust:sale.prev_customerid,
            t_fee:sale.transfer_fee,
            propertyType:sale.propertyType,
            salesManager:sale.salesManager

        });

        return sale_r.data;

    }
    
    
    UpdateTransferInstallment = async (installment) =>{
        const installment_r = await Axios.post('updatetransferinstallment.php',{
            id:installment.id,
            amount :installment.amount,
            transferid :installment.transferid,
            date :installment.date,
            nextDue :installment.nextDue,
            type :installment.type,
            paidVia :installment.paidVia,
            installmentNumber:installment.installmentNumber,
            reciptNumber:installment.reciptNumber
        });

        return installment_r.data;

    }
    UpdateInstallment = async (installment) =>{
        const installment_r = await Axios.post('updateinstallment.php',{
            id:installment.id,
            amount :installment.amount,
            saleid :installment.saleid,
            date :installment.date,
            nextDue :installment.nextDue,
            type :installment.type,
            paidVia :installment.paidVia,
            installmentNumber:installment.installmentNumber,
            reciptNumber:installment.reciptNumber
        });

        return installment_r.data;

    }
    addNewInstallment = async (installment) =>{
        const installment_r = await Axios.post('addnewinstallment.php',{
            amount :installment.amount,
            saleid :installment.saleid,
            date :installment.date,
            nextDue :installment.nextDue,
            type :installment.type,
            paidVia :installment.paidVia,
            installmentNumber:installment.installmentNumber,
            reciptNumber:installment.reciptNumber
        });

        return installment_r.data;

    }
    addnewtransferinstallment = async (installment) =>{
        const installment_r = await Axios.post('addnewtransferinstallment.php',{
            amount :installment.amount,
            transferid :installment.transferid,
            date :installment.date,
            nextDue :installment.nextDue,
            type :installment.type,
            paidVia :installment.paidVia,
            installmentNumber:installment.installmentNumber,
            reciptNumber:installment.reciptNumber
        });

        return installment_r.data;

    }
    updatecustomer = async(customer) => {

        // Sending the user registration request
        const register = await Axios.post('updatecustomer.php',{
            name:customer.name,
            email:customer.email,
            phone:customer.phone,
            cnic:customer.cnic,
            id:customer.id
        });

        return register.data;
    }
    addNewCustomer = async (customer) => {

        // Sending the user registration request
        const register = await Axios.post('addcustomer.php',{
            name:customer.name,
            email:customer.email,
            phone:customer.phone,
            cnic:customer.cnic
        });

        return register.data;
    }
    smsapi= async (sms) => {

        // Sending the user registration request
        const _sms = await Axios.post('smsapi.php',{
            username:sms.username,
            pass:sms.pass,
            brand:sms.brand
        });

        return _sms.data;
    }
    getAllCustomers = async () => {
        const users = await Axios.post('getallcustomers.php');
        return users.data;
    }

    getallfiles = async () => {
        const files = await Axios.post('getallfiles.php');
        return files.data;
    }


    getInstallmetntsBySaleId = async (saleid) => {
        const installments = await Axios.post('getinstallmentsbysaleid.php',{
            saleid:saleid
        });
        return installments.data;
    }

    GetTransferById = async(id)=>{
        const installments = await Axios.post('gettranferbyid.php',{
            id:id
        });
        return installments.data;
    }

    getinstallmentsbytransferid = async (tid) => {
        const installments = await Axios.post('getinstallmentsbytransferid.php',{
            transferid:tid
        });
        return installments.data;
    }
    getAllSales = async () => {
        const sales = await Axios.post('getallsales.php');
        return sales.data;
    }
    getalltransfers = async()=>{
        const sales = await Axios.post('getalltransfers.php');
        return sales.data;
    }
    getAllUsers = async () => {
        const users = await Axios.post('getallusers.php');
        return users.data;
    }
    getSmsApi = async () => {
        const sms = await Axios.post('getsmsapi.php');
        return sms.data;
    }
    getInvoiceOnInstallmentId=async(installmentid,saleid)=>{
        const users = await Axios.post('getinvoicedetail.php',{
            installmentid:installmentid,
            saleid:saleid
        });
        return users.data;
    }
    getcustomerbyid=async(id)=>{
        const users = await Axios.post('getcustomerbyid.php',{
            id:id
        });
        return users.data;
    }
    getsalebyid=async(id)=>{
        const users = await Axios.post('getsalebyid.php',{
            id:id
        });
        return users.data;
    }
    
    DeleteSale = async(id)=>{
        const users = await Axios.post('deletesalebyid.php',{
            id:id
        });
        return users.data;
    }
    DeleteCustomer = async(id)=>{
        const users = await Axios.post('deletecustomerbyid.php',{
            id:id
        });
        return users.data;
    }
    
    DeleteInstallment = async(id)=>{
        const users = await Axios.post('deleteinstallment.php',{
            id:id
        });
        return users.data;
    }
    DeleteTransferInstallment = async(id)=>{
        const users = await Axios.post('deletetransferinstallment.php',{
            id:id
        });
        return users.data;
    }
    DeleteTransfer =async(id)=>{
        const users = await Axios.post('deletetransfer.php',{
            id:id
        });
        return users.data;
    }
    gettransferincoice=async(installmentid,transferid)=>{
        const users = await Axios.post('gettransferincoice.php',{
            installmentid:installmentid,
            transferid:transferid
        });
        return users.data;
    }
    
    sendSms=async(data,u)=>{
            const sms = await Axios.post('sendsmstouser.php',{
                username:data.username,
                pass:data.pass,
                phone:u.number,
                brand:data.brand,
                message:u.message
            });
            return sms.data;
        }

    // Checking user logged in or not
    isLoggedIn = async () => {
        const loginToken = localStorage.getItem('loginToken');

        // If inside the local-storage has the JWT token
        if(loginToken){

            //Adding JWT token to axios default header
            Axios.defaults.headers.common['Authorization'] = 'bearer '+loginToken;

            // Fetching the user information
            const {data} = await Axios.get('user-info.php');

            // If user information is successfully received
            if(data.success && data.user){
                this.setState({
                    ...this.state,
                    isAuth:true,
                    theUser:data.user
                });
            }

        }
    }

    render(){
        const contextValue = {
            rootState:this.state,
            toggleNav:this.toggleNav,
            isLoggedIn:this.isLoggedIn,
            registerUser:this.registerUser,
            loginUser:this.loginUser,
            logoutUser:this.logoutUser,
            getAllUsers:this.getAllUsers,
            getAllCustomers:this.getAllCustomers,
            addNewCustomer:this.addNewCustomer,
            getAllSales:this.getAllSales,
            addnewsale:this.addnewsale,
            getInstallmetntsBySaleId:this.getInstallmetntsBySaleId,
            getInvoiceOnInstallmentId:this.getInvoiceOnInstallmentId,
            addNewInstallment:this.addNewInstallment,
            getallfiles:this.getallfiles,
            addnewtransfer:this.addnewtransfer,
            getalltransfers:this.getalltransfers,
            getinstallmentsbytransferid:this.getinstallmentsbytransferid,
            addnewtransferinstallment:this.addnewtransferinstallment,
            gettransferincoice:this.gettransferincoice,
            getSmsApi:this.getSmsApi,
            smsapi:this.smsapi,
            sendSms:this.sendSms,
            updatecustomer:this.updatecustomer,
            getcustomerbyid:this.getcustomerbyid,
            DeleteCustomer:this.DeleteCustomer,
            updatesale:this.updatesale,
            getsalebyid:this.getsalebyid,
            DeleteSale:this.DeleteSale,
            UpdateInstallment:this.UpdateInstallment,
            DeleteInstallment:this.DeleteInstallment,
            UpdateTransferInstallment:this.UpdateTransferInstallment,
            DeleteTransferInstallment:this.DeleteTransferInstallment,
            DeleteTransfer:this.DeleteTransfer,
            GetTransferById:this.GetTransferById,
            updatetransfer:this.updatetransfer
        }
        return(
            <MyContext.Provider value={contextValue}>
                {this.props.children}
            </MyContext.Provider>
        )
    }

}

export default MyContextProvider;