import React, {useContext,useState,useEffect} from 'react'
import {MyContext} from '../contexts/MyContext';
import swal from 'sweetalert2';

function Updatesale(props){
    const {getAllCustomers,updatesale,getsalebyid} = useContext(MyContext);
    
    const initialState = {
        sale:{
            sid:props.id,
            plot :'',
            customerID :'',
            salePrice :'',
            saledate :'',
            paymentPeriod :'',
            installmentType :'',
            fileNumber :'',
            plotSize :'',
            propertyType:'',
            salesManager:''
        },
        errorMsg:'',
        successMsg:'',
    }
    const [state,setState] = useState(initialState);
    let [users,setUsers] = useState([]);

    
    const getAllUsers = async () =>{
        const usersData= await getAllCustomers();
        if(usersData.message!=="No records found in database!")
        setUsers(usersData);
    }
    const getSaleRecord=async(id) =>{
        var data=await getsalebyid(props.id);
        setState({
            ...state,
            sale:{
                ...state.sale,
                plot: data[0].plot,
                customerID: data[0].customerID,
                salePrice: data[0].salePrice,
                saledate: data[0].saledate,
                paymentPeriod: data[0].paymentPeriod,
                installmentType: data[0].installmentType,
                fileNumber: data[0].fileNumber,
                plotSize: data[0].plotSize,
                propertyType: data[0].propertyType,
                salesManager: data[0].salesManager
            }
        });
    }
    useEffect(()=>{
        getAllUsers(); 
        getSaleRecord(props.id);
      },[]);
    
    // On Submit the Registration Form
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await updatesale(state.sale);
        if(data.success){
            setState({
                ...initialState,
                successMsg:data.message,
            });
            swal.fire({
                title: 'Success!',
                text: data.message,
                icon: 'success',
                showConfirmButton: false,
                toast:true,
                position:'top-right',
                timer: 2000,
                timerProgressBar: true
              });
              window.location.reload();
        }
        else{
            console.log(data);
            setState({
                ...state
            });
            swal.fire({
                title: 'Error!',
                text: data.message,
                icon: 'error',
                confirmButtonText: 'Retry'
              });
        }
    }

    const _user = users.map((item,i)=>(<option value={item.id} key={i}>{item.name}</option>));

    // On change the Input Value (name, email, password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            sale:{
                ...state.sale,
                [e.target.name]:e.target.value,
                errorMsg:''
            }
        });
    }
    
        
    return(
                    <div className="card">
                        <div className="card-body">
                            <h4 className="login-box-msg">Update Plot Sale</h4>

                            <form  onSubmit={submitForm} >
                                
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="customerID" className="label">Select Customer: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        
                                        <select  name="customerID" className="form-control" required value={state.sale.customerID} onChange={onChangeValue}>
                                            <option value="">...</option>
                                            {_user}
                                        </select>
                                    
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-user"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="plot" className="label">Plot Location: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="plot"  className="form-control" required type="text" value={state.sale.plot} onChange={onChangeValue} placeholder="Plot Location"/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-map-marker"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="plotSize" className="label">Plot Size: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="plotSize" className="form-control" required type="text" value={state.sale.plotSize} onChange={onChangeValue} placeholder="Plot Size"/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-chart-line"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="salePrice" className="label">Plot Total Price: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="salePrice" className="form-control" required type="text" value={state.sale.salePrice} onChange={onChangeValue} placeholder="Plot Price"/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-money-check"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="saledate" className="label">Sale Date: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="saledate" className="form-control" placeholder="yyyy-mm-dd" min="1997-01-01" max="2099-12-31" required type="date" value={state.sale.saledate} onChange={onChangeValue}/>
                                        
                                    </div>
                                </div>
                                
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="propertyType" className="label">Property Type: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <select name="propertyType" className="form-control" required value={state.sale.propertyType} onChange={onChangeValue}>
                                            <option>...</option>
                                            <option value="Commercial">Commercial</option>
                                            <option value="Resedential">Resedential</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="salesManager" className="label">Sales Manager: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="salesManager" className="form-control" required type="text" value={state.sale.salesManager} onChange={onChangeValue} placeholder="Sales Manager"/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-clock"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="paymentPeriod" className="label">Payment Period: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="paymentPeriod" className="form-control" required type="text" value={state.sale.paymentPeriod} onChange={onChangeValue} placeholder="Payment Period"/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-clock"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="installmentType" className="label">Installment Type: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="installmentType" className="form-control" required type="text" value={state.sale.installmentType} onChange={onChangeValue} placeholder="Monthly/Yearly"/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-user-circle"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="fileNumber" className="label">File Number: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="fileNumber" className="form-control" required type="text" value={state.sale.fileNumber} onChange={onChangeValue} placeholder="FCC123456"/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-file"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col-12">
                                        <button type="submit" className="btn btn-primary btn-block">Update Sale</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
    );
}

export default Updatesale;