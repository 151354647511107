import React, { useContext, useState, useEffect } from 'react';
import { MyContext } from '../contexts/MyContext'
import './../assets/invoicePrint.css';

function SingleTransferRecipt(props) {
    const { rootState,  gettransferincoice } = useContext(MyContext);
    const {isAuth} = rootState;
    const [invoice,setInvoice] = useState([]);
    const InvoiceData = async (iid,tid) => {
      const data = await gettransferincoice(iid,tid);
      setInvoice(data);
  }
  
    
  useEffect(()=>{
        
    InvoiceData(props.installmentid,props.transferid);
  },[]);
 
    
  const PrintArea = () => {
    var divToPrint = document.getElementById("invoice");
    window.print(divToPrint.innerHTML);
  }
  
  if(isAuth){
   

    return(
        invoice.map((invoice)=>
        <div className="card" key="">
            <div className="card-body" id="invoice">
             
          <div className="row" >
                <div className="col col-xs-3 text-left" style={{float:"left"}}>
                  <p className="invoice-from text-left">
                      <img width="180" src={require("./../assets/fcc_logo.png")} alt="Invoice logo" />                          
                  </p>
              </div>
              <div className="col col-xs-6 text-left">
                <p style={{textAlign:'center', fontSize:"16pt"}}><b>ALFALAH HOUSING PROJECT</b></p>
              </div>
                <div className="col col-xs-3 text-right" style={{ float: 'right' }}>
                  
                    <ul className="list-unstyled text-right">
                      <li><strong>Invoiced To</strong></li>
                      <li><strong>Name:</strong>&nbsp;&nbsp;{invoice.custName}</li>
                      <li><strong>Email:</strong>&nbsp;&nbsp;{invoice.custEmail}</li>
                      <li><strong>Phone:</strong>&nbsp;&nbsp;{invoice.custPhone}</li>
                    </ul>
                </div>
          </div>
            <hr/>
            <div className="row">
              <div className="col-lg-12">
                        <div className="invoice-details text-left">
                          <div className="row">
                              <div className="col col-xs-4"><strong>Invoice</strong> #{invoice.invoiceId}</div>
                              <div className="col col-xs-4"><strong>Invoice Date:</strong>&nbsp;&nbsp; {invoice.date} </div>
                              <div className="col col-xs-4"><strong>Status:</strong>&nbsp;&nbsp; <span className="label label-danger">PAID</span></div>
                            
                          </div>
                        </div>
                <div className="invoice-items">
                  <div className="table-responsive" style={{ overflow: 'hidden', outline: 'none' }} tabIndex="0">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th className="per70 text-center">Description</th>
                          <th className="per25 text-center">File Number</th>
                          <th className="per25 text-center">Installment Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td> {invoice.type} installment paid via {invoice.paidVia} for {invoice.plotSize} Located at: {invoice.plotLocation}</td>
                          <td>{invoice.fileNum}</td>
                          <td className="text-center">Rs. {invoice.amount}</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colSpan="2" className="text-right">Total Plot Price:</th>
                          <th className="text-center">Rs. {invoice.totalPlotPrice}</th>
                        </tr>
                        <tr>
                          <th colSpan="2" className="text-right">Total Paid:</th>
                          <th className="text-center">Rs. {invoice.totalPaidSoFar}</th>
                        </tr>
                        <tr>
                          <th colSpan="2" className="text-right">Total Remaining:</th>
                          <th className="text-center">Rs. {invoice.balance}</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <table className="col-lg-12">
                    <tbody>
                        <tr>
                        <td className="text-left">
                            <h5><b>Bank Details</b></h5>
                            <div className="col-lg-12">
                              <p>Bank: UBL ** A/C #: PK55UNIL0109000239712989 ** Acc. Title: WAJID ISHAQ<br/>
                              Bank: ABL ** A/C #: PK75ABPA0010023098800027 ** Acc. Title: Kh. M. Fazail Butt<br/>
                              Bank: ABL ** A/C #: PK10ABPA0010023098800033 ** Acc. Title: Kh. M. Fazail Butt<br />
                              Bank: MCB ** A/C #: PK49MUCB1219501551011988 ** Acc. Title: Kh. M. Fazail Butt<br/>
                              Bank: MBL ** A/C #: PK67MEZN0093010104715322 ** Acc. Title: Kh. M. Fazail Butt</p>
                            </div>
                        </td>

                        <td className="text-right">
                            <div className="col-lg-12">
                              <img width="200" src={require("./../assets/signature.png")} alt="Invoice logo" />
                              <br/>
                              <p style={{borderTop: '2px solid', width:'400px' ,float:"right" }} >Signature & Stamp</p>
                            </div>
                          </td>
                          
                          
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="mt25 text-center">
                  <hr />
                  <p>FAAIZ CONSTRUCTION COMPANY | www.faaizconstruction.com
                                        <br /> ALFALAH PLAZA NEAR BYPASS ROAD SPORTS COMPLEX RAWALAKOT
                                        <br />   +92 346 5481921 | +92 345 5060779 | +92 300 5169279</p>
                </div>
                <div className="invoice-footer mt25 no-print">
                  <p className="text-center "><a href="#" className="btn btn-default ml15" onClick={PrintArea}><i className="fa fa-print mr5"></i> Print</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        )
    );
  }else return('');
}

export default SingleTransferRecipt;