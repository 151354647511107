import React from 'react';


function Footer (){
  return ( 
  <footer className="main-footer no-print">
    <div className="float-right d-none d-sm-block">
      <b>Version 1.0.0</b>
    </div>
    <strong>Copyright © 2020 <a href="mailto:danyalr8@gmail.com">Danyal Rashid</a>.</strong> All rights
    reserved.
  </footer>
  )
}

export default Footer;