import React, { useContext, useState } from 'react';
import { MyContext } from '../contexts/MyContext';
import swal from 'sweetalert2';

function Updateinstallment(props) {
    const { UpdateInstallment } = useContext(MyContext);
    
  const iState = {
    installment:{
        id:props.id,
        amount:props.amount,
        saleid:props.id,
        date:props.date,
        nextDue:props.nextDue,
        type:props.type,
        paidVia:props.paidVia,
        installmentNumber:props.installmentNumber,
        reciptNumber:props.reciptNumber
    }
  };

  
  const [state,setState]=useState(iState);
  
  const onChangeValue = (e) => {
    setState({
        ...state,
        installment:{
            ...state.installment,
            [e.target.name]:e.target.value
        }
    });
  }

  const submitForm = async (event) => {
    event.preventDefault();
    swal.fire({
      title: 'Success!',
      text: 'Installment is being updated! Please wait...',
      icon: 'success',
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true
    });
    const data = await UpdateInstallment(state.installment);
    
  
    if (data.success) {
      setState({
        installment:{
          amount:'',
          saleid:'',
          date:'',
          nextDue:'',
          type:'',
          paidVia:'',
          installmentNumber:'',
          reciptNumber:''
          }
      });
      swal.fire({
        title: 'Success!',
        text: data.message,
        icon: 'success',
        showConfirmButton: false,
        toast: true,
        position: 'top-right',
        timer: 2000,
        timerProgressBar: true
      });

      window.location.reload();
    }
    else {
      swal.fire({
        title: 'Error!',
        text: data.message,
        icon: 'error',
        confirmButtonText: 'Retry'
      });
    }
  }
  return(
    <div className="card  no-print">
    <div className="card-body">
      <h4 className="login-box-msg">Update installment: {state.installment.id}</h4>
      <form onSubmit={submitForm} >
        <div className="row p-3">
          <div className="col-sm-3">
            <label htmlFor="saleid" className="label">Sale Id: </label>
          </div>
          <div className="input-group col-sm-9">
            <input name="saleid" className="form-control" required type="text" value={state.installment.saleid} onChange={onChangeValue} readOnly />
          </div>
        </div>
        <div className="row p-3">
          <div className="col-sm-3">
            <label htmlFor="amount" className="label">Installment Amount: </label>
          </div>
          <div className="input-group col-sm-9">
            <input name="amount" className="form-control" required type="text" value={state.installment.amount}  onChange={onChangeValue} placeholder="10000" />
          </div>
        </div>
        
        <div className="row p-3">
          <div className="col-sm-3">
            <label htmlFor="installmentNumber" className="label">Installment Number: </label>
          </div>
          <div className="input-group col-sm-9">
            <input name="installmentNumber" className="form-control" required  value={state.installment.installmentNumber}  onChange={onChangeValue}  type="text" placeholder='Installment Number' />
          </div>
        </div>

        
        <div className="row p-3">
          <div className="col-sm-3">
            <label htmlFor="reciptNumber" className="label">Trx Id/Cheque Number/TrensferID: </label>
          </div>
          <div className="input-group col-sm-9">
            <input name="reciptNumber" className="form-control"  value={state.installment.reciptNumber}  onChange={onChangeValue}  type="text" placeholder='Trx Id/Cheque Number/TrensferID' />
          </div>
        </div>

        <div className="row p-3">
          <div className="col-sm-3">
            <label htmlFor="date" className="label">Payment Date: </label>
          </div>
          <div className="input-group col-sm-9">
            <input name="date" className="form-control" required  value={state.installment.date}  onChange={onChangeValue}  type="date" />
          </div>
        </div>

        <div className="row p-3">
          <div className="col-sm-3">
            <label htmlFor="nextDue" className="label">Next Due Date: </label>
          </div>
          <div className="input-group col-sm-9">
            <input name="nextDue" className="form-control"  value={state.installment.nextDue}  onChange={onChangeValue}  required type="date" />
          </div>
        </div>

        <div className="row p-3">
          <div className="col-sm-3">
            <label htmlFor="type" className="label">Installment Type: </label>
          </div>
          <div className="input-group col-sm-9">
            <select name="type" className="form-control"  value={state.installment.type}  onChange={onChangeValue}  required >
                <option>...</option>
              <option value="Monthly">Monthly</option>
              <option value="Down Payment">Down Payment</option>
            </select>
          </div>
        </div>
        <div className="row p-3">
          <div className="col-sm-3">
            <label htmlFor="paidVia" className="label">Paid Via: </label>
          </div>
          <div className="input-group col-sm-9">
            <select name="paidVia" className="form-control"  value={state.installment.paidVia}  onChange={onChangeValue}  required >
                <option>...</option>
              <option value="Cheque">Cheque</option>
              <option value="Online Bank Transfer">Online Bank Transfer</option>
              <option value="Cash">Cash</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-12">
            <button type="submit" className="btn btn-primary btn-block">Update Installment</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  );
}


export default Updateinstallment;