import React, {useContext,useState,useEffect} from 'react'
import {MyContext} from '../contexts/MyContext'
import swal from 'sweetalert2';
import Login from './Login';
import Register from './Register';
import Header from './../components/Header';
import Footer from './../components/Footer';
import Sidebar from './../components/Sidebar';

function Apisettings(){
    const {rootState,smsapi,getSmsApi} = useContext(MyContext);
    const {isAuth,showLogin} = rootState;
    const initialState = {
        userInfo:{
            username:'',
            pass:'',
            brand:''
        },
        errorMsg:'',
        successMsg:'',
    }
    const [state,setState] = useState(initialState);

   
    const getSms=async()=>{
        const _s=await getSmsApi();
        setState({
            userInfo:{
                username:_s[0].username,
                pass:_s[0].password,
                brand:_s[0].brandname
            }
        });
    }
    
    useEffect(()=>{
        
        getSms();
          },[]);
    
        if(isAuth)
        {
    // On Submit the Registration Form
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await smsapi(state.userInfo);
        if(data.success){
            setState({
                successMsg:data.message,
            });
            swal.fire({
                title: 'Success!',
                text: data.message,
                icon: 'success',
                showConfirmButton: false,
                toast:true,
                position:'top-right',
                timer: 2000,
                timerProgressBar: true
              });
        }
        else{
            setState({
                ...state,
                successMsg:'',
                errorMsg:data.message
            });
            swal.fire({
                text: data.message,
                icon: 'success',
                confirmButtonText: 'OK'
              });
        }
    }

    // On change the Input Value (name, email, password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value,
                errorMsg:''
            }
        });
    }
    
    return(
        
    <div className="wrapper">                    
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{minHeight: '960px'}}>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="login-box-msg">Set sms api</h4>

                            <form  onSubmit={submitForm} >
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="username" className="label">Username: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="username"  className="form-control" required type="text" value={state.userInfo.username} onChange={onChangeValue} placeholder="923000000000"/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-user"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="pass" className="label">Password: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="pass" className="form-control" type="text" value={state.userInfo.pass} onChange={onChangeValue} placeholder="************"/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-user-circle"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="brand" className="label">Brandname: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="brand" className="form-control" required type="text" value={state.userInfo.brand} onChange={onChangeValue} placeholder="Brand Name"/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-phone"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col-12">
                                        <button type="submit" className="btn btn-primary btn-block">Update API Settings</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <Footer />
    </div>
    );
}
// Showing Login Or Register Page According to the condition
else if(showLogin){
    
    return <Login/>;
}
else{
    return <Register/>;
}
}

export default Apisettings;