import React from 'react';
// Importing the Context Provider & Home Component
import  { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import MyContextProvider from './contexts/MyContext';
import Home from './pages/Home'
import Listalluser from './pages/Listallusers';
import NotFound from './pages/404';
import Login from './pages/Login';
import Register from './pages/Register';
import Listallcustomers from './pages/Listallcustomers';
import Addnewcustomer from './pages/Addnewcustomer';
import Listallsales from './pages/Listallsales';
import Addnewsale from './pages/Addnewsale';
import Listallfiletransfers from './pages/Listallfiletransfers';
import Newfiletransfer from './pages/Newfiletransfer';
import Apisettings from './pages/Apisettings';
import Sendsmstouser from './pages/Sendsmstouser';
function App() {
  
  return (
    <MyContextProvider>
        <Router >
          <Switch>
            <Route exact path = "/"><Home /></Route>
            
            <Route path = "/home" component={Home}></Route>
            <Route path = "/admin"  component={Home}></Route>
            <Route path = "/login" component={Login}></Route>
            <Route path = "/register" component={Register}></Route>
            <Route path = "/listallusers" component={Listalluser} ></Route>
            <Route path = "/listallcustomers" component={Listallcustomers} ></Route>
            <Route path = "/createcustomer" component={Addnewcustomer} ></Route>
            <Route path = "/listallallsales" component={Listallsales} ></Route>
            <Route path = "/addnewsale" component={Addnewsale} ></Route>
            <Route path = "/transferdetails" component={Listallfiletransfers} ></Route>
            <Route path = "/transferfile" component={Newfiletransfer} ></Route>
            <Route path = "/setsmsapi" component={Apisettings} ></Route>
            <Route path = "/sendsmstocustomer" component={Sendsmstouser} ></Route>
            
            <Route path="*" exact to="/notfound" component={NotFound}></Route>
            
            </Switch>
        </Router>
    </MyContextProvider>
  );
}

export default App;