import React, { useContext } from 'react';
import {MyContext} from '../contexts/MyContext'

import Login from './Login'
import Register from './Register'
import Header from './../components/Header';
import Footer from './../components/Footer';
import Sidebar from './../components/Sidebar';

function NotFound () {


    const {rootState} = useContext(MyContext);
    const {isAuth,showLogin} = rootState;
    
    // If user Logged in
    if(isAuth)
    {
        return(
            <div className="wrapper">
                
                <Header />
                <Sidebar />
                <div className="content-wrapper" style={{minHeight: '960px'}}>
                    <div className="container-fluid">                    
                      <div className="card">
                          <div className="card-header">
                                <center><h1>404 Not Found!</h1></center>
                          </div>
                          <div className="card-body">
                          <center><h5>Sorry, requested resource isn't available!</h5></center>
                          </div>
                      </div>
                    </div>
                </div>
                <Footer />
                
                
            
            </div>
        )
    }
    // Showing Login Or Register Page According to the condition
    else if(showLogin){
        
        return <Login/>;
    }
    else{
        return <Register/>;
    }
    

  }


export default NotFound;