import React, {useContext} from 'react';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import {Link} from "react-router-dom";
import {MyContext} from '../contexts/MyContext'




function Sidebar(){
    const {rootState} = useContext(MyContext);
    const {theUser} = rootState;

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <Link to="/" className="brand-link">
              <img src={require("./../assets/fcc_logo.png")} alt="Logo" className="brand-image img-circle elevation-3"
                   style={{opacity: .8}} />
              <span className="brand-text font-weight-light">FCC</span>
            </Link>
        
            <div className="sidebar">
              <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                <div className="image">
                    <span className="img-circle elevation-2" role="img" aria-label="Profile Pic" >👦</span>
                </div>
                <div className="info">
                  <span   className="d-block">{theUser.username}</span>
                </div>
              </div>
        
              <nav className="mt-2">
                <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">        
                  <li className="nav-item has-treeview menu-open">
                  <Link to="/admin"  className="nav-link active">
                        
                  <FaIcons.FaTachometerAlt className="nav-icon" />

                      <p>
                        Dashboard
                      </p>
                    </Link>
                  </li>
                  <li className="nav-item has-treeview menu-open">
                  
                  <a href="#" className="nav-link">
                    <FaIcons.FaUserAlt className="nav-icon" />
                      <p>
                        Users
                        
                        <FaIcons.FaAngleLeft className="right" />
                        
                      </p>
                    </a>
                    <ul className="nav nav-treeview menu-open">
                      <li className="nav-item">
                        <Link to="/listallusers" className="nav-link">
                        <FaIcons.FaRegUser className="nav-icon" />
                          
                          <p>List All Users</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item has-treeview menu-open">
                  <a href="#" className="nav-link">
                    <FaIcons.FaUserGraduate className="nav-icon" />
                      <p>
                        Customers
                        <FaIcons.FaAngleLeft className="right" />
                      </p>
                    </a>
                    <ul className="nav nav-treeview menu-open">
                      <li className="nav-item">
                        <Link to="/listallcustomers" className="nav-link">
                          <FaIcons.FaList className="nav-icon" />
                          <p>List All Customers</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/createcustomer" className="nav-link">
                          <FaIcons.FaCreativeCommons className="nav-icon" />
                          <p>Add New Customer</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item has-treeview menu-open">
                  <a href="#" className="nav-link">
                    <AiIcons.AiTwotoneMoneyCollect className="nav-icon" />
                      <p>
                        Plot Sales
                        <FaIcons.FaAngleLeft className="right" />
                      </p>
                    </a>
                    <ul className="nav nav-treeview menu-open">
                      <li className="nav-item">
                        <Link to="/listallallsales" className="nav-link">
                          <FaIcons.FaSellcast className="nav-icon" />
                          <p>Sold Plot Details</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/addnewsale" className="nav-link">
                          <FaIcons.FaFile className="nav-icon" />
                          <p>Add New Plot Sale</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item has-treeview menu-open">
                  <a href="#" className="nav-link">
                    <AiIcons.AiFillEdit className="nav-icon" />
                      <p>
                        Plot File Transfer
                        <FaIcons.FaAngleLeft className="right" />
                      </p>
                    </a>
                    <ul className="nav nav-treeview menu-open">
                      <li className="nav-item">
                        <Link to="/transferdetails" className="nav-link">
                          <FaIcons.FaSellcast className="nav-icon" />
                          <p>File Transfer Details</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/transferfile" className="nav-link">
                          <AiIcons.AiOutlineTransaction className="nav-icon" />
                          <p>Transfer File</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item has-treeview menu-open">
                  <a href="#" className="nav-link">
                    <FaIcons.FaLocationArrow className="nav-icon" />
                      <p>
                        SMS
                        <FaIcons.FaAngleLeft className="right" />
                      </p>
                    </a>
                    <ul className="nav nav-treeview menu-open">
                      <li className="nav-item">
                        <Link to="/setsmsapi" className="nav-link">
                          <FaIcons.FaSms className="nav-icon" />
                          <p>SET SMS API</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/sendsmstocustomer" className="nav-link">
                          <FaIcons.FaMailBulk className="nav-icon" />
                          <p>Send SMS TO Customer</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </aside>
        
          )
            
}


export default Sidebar;