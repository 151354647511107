import React, { useContext, useState, useEffect } from 'react'
import { MyContext } from '../contexts/MyContext'
import swal from 'sweetalert2';


function Updatetransfer(props) {
    const { updatetransfer, getAllCustomers, getallfiles,GetTransferById } = useContext(MyContext);
    let [users, setUsers] = useState([]);

    let [files, setFiless] = useState([]);
    const initialState = {
        sale: {
            id:props.id,
            plot: '',
            customerID: '',
            salePrice: '',
            saledate: '',
            paymentPeriod: '',
            installmentType: '',
            fileNumber: '',
            plotSize: '',
            prev_customerid: '',
            transfer_fee: '',
            propertyType: '',
            salesManager: ''
        },
        errorMsg: '',
        successMsg: '',
    }

    const [state, setState] = useState(initialState);

    const getAllUsers = async () => {
        const usersData = await getAllCustomers();
        if (usersData.message !== "No records found in database!")
            setUsers(usersData);
    }

    const allfiles = async () => {
        const _files = await getallfiles();
        if (_files.message !== "No records found in database!")
            setFiless(_files);
    }
    const getSaleRecord=async(id) =>{
        var data=await GetTransferById(props.id);
        setState({
            ...state,
            sale:{
                ...state.sale,
                plot: data[0].plot,
                customerID: data[0].customerID,
                salePrice: data[0].salePrice,
                saledate: data[0].saledate,
                paymentPeriod: data[0].paymentPeriod,
                installmentType: data[0].installmentType,
                fileNumber: data[0].fileNumber,
                plotSize: data[0].plotSize,
                prev_customerid: data[0].prev_customerid,
                transfer_fee: data[0].transfer_fee,
                propertyType: data[0].propertyType,
                salesManager: data[0].salesManager
            }
        });
    }
    useEffect(() => {
        getAllUsers();
        allfiles();
        getSaleRecord(props.id);
    }, []);

    // On Submit the Registration Form
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await updatetransfer(state.sale);
        if (data.success) {
            setState({
                ...initialState,
                successMsg: data.message,
            });
            swal.fire({
                title: 'Success!',
                text: data.message,
                icon: 'success',
                showConfirmButton: false,
                toast: true,
                position: 'top-right',
                timer: 2000,
                timerProgressBar: true
            });
            window.location.reload();
        }
        else {
            console.log(data);
            setState({
                ...state
            });
            swal.fire({
                title: 'Error!',
                text: data.message,
                icon: 'error',
                confirmButtonText: 'Retry'
            });
        }
    }

    const findArrayElementByTitle = (array, fileNumber) => {
        return array.find((element) => {
            return element.fileNumber === fileNumber;
        }
        );
    }

    const _files = (files.map((item, i) => (<option value={item.fileNumber}>{item.fileNumber}</option>)));

    const _user = users.map((item, i) => (<option value={item.id}>{item.name}</option>));
    // On change the Input Value (name, email, password)
    const onChangeValue = (e) => {

        if (e.target.name === "fileNumber") {
            const t = findArrayElementByTitle(files, e.target.value);
            state.sale.prev_customerid = t.customerID;
            state.sale.propertyType = t.propertyType;
            state.sale.plot = t.plot;
            state.sale.plotSize = t.plotSize;
        }

        setState({
            ...state,
            sale: {
                ...state.sale,
                [e.target.name]: e.target.value,
                errorMsg: ''
            }
        });

    }


    return (
        <div className="card">
            <div className="card-body">
                <h4 className="login-box-msg">Update sale of Plot</h4>

                <form onSubmit={submitForm} >


                    <div className="row p-3">
                        <div className="col-sm-3">
                            <label htmlFor="fileNumber" className="label">File Number to transfer: </label>
                        </div>
                        <div className="input-group col-sm-9">
                            <input name="fileNumber" className="form-control" type="text" required value={state.sale.fileNumber} onChange={onChangeValue} readOnly />
                            
                        </div>
                    </div>
                    <div className="row p-3">
                        <div className="col-sm-3">
                            <label htmlFor="customerID" className="label">Transfer to Customer: </label>
                        </div>
                        <div className="input-group col-sm-9">

                            <select name="customerID" className="form-control" required value={state.sale.customerID} onChange={onChangeValue}>
                                <option >...</option>
                                {_user}
                            </select>

                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-user"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row p-3">
                        <div className="col-sm-3">
                            <label htmlFor="propertyType" className="label">Property Type: </label>
                        </div>
                        <div className="input-group col-sm-9">
                            <input name="propertyType" className="form-control" type="text" required value={state.sale.propertyType} onChange={onChangeValue} readonly="readonly" />
                            
                        </div>
                    </div>
                    <div className="row p-3">
                        <div className="col-sm-3">
                            <label htmlFor="salesManager" className="label">Sales Manager: </label>
                        </div>
                        <div className="input-group col-sm-9">
                            <input name="salesManager" className="form-control" required type="text" value={state.sale.salesManager} onChange={onChangeValue} placeholder="Sales Manager" />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-clock"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row p-3">
                        <div className="col-sm-3">
                            <label htmlFor="plot" className="label">Plot Location: </label>
                        </div>
                        <div className="input-group col-sm-9">
                            <input name="plot" className="form-control" required type="text" value={state.sale.plot} onChange={onChangeValue} placeholder="Plot Location" readonly="readonly" />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-map-marker"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row p-3">
                        <div className="col-sm-3">
                            <label htmlFor="plotSize" className="label">Plot Size: </label>
                        </div>
                        <div className="input-group col-sm-9">
                            <input name="plotSize" className="form-control" readonly="readonly" required type="text" value={state.sale.plotSize} onChange={onChangeValue} placeholder="Plot Size" />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-chart-line"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row p-3">
                        <div className="col-sm-3">
                            <label htmlFor="salePrice" className="label">Plot Total Price: </label>
                        </div>
                        <div className="input-group col-sm-9">
                            <input name="salePrice" className="form-control" required type="text" value={state.sale.salePrice} onChange={onChangeValue} placeholder="Plot Price" />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-money-check"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row p-3">
                        <div className="col-sm-3">
                            <label htmlFor="transfer_fee" className="label">Transfer Fee: </label>
                        </div>
                        <div className="input-group col-sm-9">
                            <input name="transfer_fee" className="form-control" required type="text" value={state.sale.transfer_fee} onChange={onChangeValue} placeholder="Transfer Fee" />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-money-check"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row p-3">
                        <div className="col-sm-3">
                            <label htmlFor="saledate" className="label">Transfer Date: </label>
                        </div>
                        <div className="input-group col-sm-9">
                            <input name="saledate" className="form-control" placeholder="yyyy-mm-dd" min="1997-01-01" max="2099-12-31" required type="date" value={state.sale.saledate} onChange={onChangeValue} />

                        </div>
                    </div>
                    <div className="row p-3">
                        <div className="col-sm-3">
                            <label htmlFor="paymentPeriod" className="label">Payment Period: </label>
                        </div>
                        <div className="input-group col-sm-9">
                            <input name="paymentPeriod" className="form-control" required type="text" value={state.sale.paymentPeriod} onChange={onChangeValue} placeholder="Payment Period" />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-clock"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row p-3">
                        <div className="col-sm-3">
                            <label htmlFor="installmentType" className="label">Installment Type: </label>
                        </div>
                        <div className="input-group col-sm-9">
                            <input name="installmentType" className="form-control" required type="text" value={state.sale.installmentType} onChange={onChangeValue} placeholder="Monthly/Yearly" />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-user-circle"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-12">
                            <button type="submit" className="btn btn-primary btn-block">Update Sale</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Updatetransfer;