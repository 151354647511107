import React, { Component } from 'react';


class Content extends Component {
  render() {



    return (
        <div >
            Dashboard content yet to setup!
        </div>
    );
  }
}

export default Content;