import React, {useContext, useState} from 'react'
import {MyContext} from '../contexts/MyContext'
import swal from 'sweetalert2';

function Login(){

    const {toggleNav,loginUser,isLoggedIn} = useContext(MyContext);

    const initialState = {
        userInfo:{
            email:'',
            password:'',
        },
        errorMsg:'',
        successMsg:'',
    }

    const [state,setState] = useState(initialState);

    // On change input value (email & password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value
            }
        });
    }

    // On Submit Login From
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await loginUser(state.userInfo);
        if(data.success && data.token){
            setState({
                ...initialState,
            });
            localStorage.setItem('loginToken', data.token);
            await isLoggedIn();
        }
        else{
            console.log(data);
            setState({
                ...state,
                successMsg:'',
                errorMsg:data.message
            });
            swal.fire({
                title: 'Error!',
                text: data.message,
                icon: 'error',
                confirmButtonText: 'Retry'
              })
        }
    }
    return(
        
            <div className="hold-transition login-page">
            <div className="login-box">
                <div className="login-logo">
                    <b>FCC ADMIN</b>
                </div>
    
                <div className="card">
                    <div className="card-body login-card-body">
                        <p className="login-box-msg">Sign in to start your session</p>

                        <form onSubmit={submitForm}>
                            <div className="input-group mb-4">
                                <input name="email" type="text" className='form-control' required placeholder="Enter your email" value={state.userInfo.email} onChange={onChangeValue} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-4">
                                <input name="password" type="password"  className='form-control' required placeholder="Enter your password" value={state.userInfo.password} onChange={onChangeValue} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock"></span>
                                    </div>
                                </div>
                            </div>
                            <center>
                                <div className="col col-8">
                                    <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                                </div>
                            </center>
                            <hr />
                            <center>
                            <div className="col col-8">
                                    <button className="btn btn-primary btn-block" onClick={toggleNav}>Register</button>
                                </div>
                            </center>
                            
                        </form>
                    </div>
                </div>
            </div>
            </div>        
    );
}

export default Login;