import React, {useContext,useState} from 'react'
import {MyContext} from '../contexts/MyContext'
import swal from 'sweetalert2';
import Home from './Home';
import Login from './Login';
function Register(){
    const {rootState,toggleNav,registerUser} = useContext(MyContext);
    const {isAuth,showLogin} = rootState;
    const initialState = {
        userInfo:{
            username:'',
            firstname:'',
            lastname:'',
            phone:'',
            email:'',
            password:''
        },
        errorMsg:'',
        successMsg:'',
    }
    const [state,setState] = useState(initialState);

    // On Submit the Registration Form
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await registerUser(state.userInfo);
        if(data.success){
            setState({
                ...initialState,
                successMsg:data.message,
            });
            swal.fire({
                title: 'Success!',
                text: data.message,
                icon: 'success',
                showConfirmButton: false,
                toast:true,
                position:'top-right',
                timer: 2000,
                timerProgressBar: true
              });
        }
        else{
            setState({
                ...state,
                successMsg:'',
                errorMsg:data.message
            });
            swal.fire({
                title: 'Error!',
                text: data.message,
                icon: 'error',
                confirmButtonText: 'Retry'
              });
        }
    }

    // On change the Input Value (name, email, password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value,
                errorMsg:''
            }
        });
    }
    
    if(isAuth){
        return (<Home />);
    }else if(showLogin){
        return (<Login />);
    }
    else{
    return(
        
    <div className="hold-transition register-page">
        <div className="register-box">
            <div className="register-logo">
                <b>FCC ADMIN</b>
            </div>
            <div className="card">
                <div className="card-body register-card-body">
                    <p className="login-box-msg">Register a new membership</p>

                    <form  onSubmit={submitForm} >
                        <div className="input-group mb-3">
                            <input name="username"  className="form-control" required type="text" value={state.userInfo.username} onChange={onChangeValue} placeholder="Enter username"/>
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-user"></span>
                                </div>
                            </div>
                        </div>


                        <div className="input-group mb-3">
                            <input name="firstname" className="form-control" required type="text" value={state.userInfo.firstname} onChange={onChangeValue} placeholder="John"/>
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-user-circle"></span>
                                </div>
                            </div>
                        </div>
                        
                        <div className="input-group mb-3">
                            <input name="lastname" className="form-control" required type="text" value={state.userInfo.lastname} onChange={onChangeValue} placeholder="Doe"/>
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-user-plus"></span>
                                </div>
                            </div>
                        </div>

                        <div className="input-group mb-3">
                            <input name="phone" className="form-control" required type="text" value={state.userInfo.phone} onChange={onChangeValue} placeholder="92xxxxxxxxx"/>
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-phone"></span>
                                </div>
                            </div>
                        </div>

                        <div className="input-group mb-3">
                            <input name="email" className="form-control" required type="email" value={state.userInfo.email} onChange={onChangeValue} placeholder="example@org.com"/>
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-envelope"></span>
                                </div>
                            </div>
                        </div>
                        
                        <div className="input-group mb-3">
                            <input name="password"  className="form-control"  required type="password" value={state.userInfo.password} onChange={onChangeValue} placeholder="**********"/>
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-lock"></span>
                                </div>
                            </div>
                        </div>
                        

                        <div className="row">
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary btn-block">Register</button>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-12">
                                <button className="btn btn-primary btn-block"  onClick={toggleNav} >Already registered? Login Here!</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    );
}
}

export default Register