import React, { useContext,useState,useEffect } from 'react';
import {MyContext} from '../contexts/MyContext'
import Login from './Login'
import Register from './Register'
import Header from './../components/Header';
import Footer from './../components/Footer';
import Sidebar from './../components/Sidebar';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import Updatecustomer from './Updatecustomer';
import swal from 'sweetalert2';

function Listallcustomers (props) {
    
    
    const {rootState,getAllCustomers,DeleteCustomer} = useContext(MyContext);
    const {isAuth,showLogin} = rootState;
    let [rows, setRows] = useState([]);
    let [_viewUpdateUser, setViewForUpdate] = useState([]);
    const columns = [
      {
        name: 'Customer Name',
        selector: 'name',
        sortable: true
      },
      {
        name: 'Customer CNIC',
        selector: 'cnic',
        sortable: true
      },
      {
        name: 'Phone',
        selector: 'phone',
        sortable: true
      },
      {
        name: 'Email',
        selector: 'email',
        sortable: true
      },
      {
        name: 'Action',
        sortable: false,
        cell: (row2) => 
        <span style={{display:"inline-grid"}}>
          <button onClick={() => {UpdateCustomer(row2.id)}} id={row2.id} name="edit" className="btn btn-outline-success btn-sm">EDIT</button>
          {parseInt(row2.numSales)===0?<button onClick={() => {DelCustomer(row2.id)}} id={row2.id} name="edit" className="btn btn-outline-danger btn-sm">DELETE</button>:null}
          
        </span>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      }
    ];

    const UpdateCustomer=(id)=>{
      setViewForUpdate(<Updatecustomer id={id}/>);
    }
    const DelCustomer=(id)=>{
      
      const data= DeleteCustomer(id);
      
      swal.fire({
        title: 'Success!',
        text: data.message,
        icon: 'success',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true
      });
    window.location.reload();
    }

    const Userdata = async()=> {
      const data =  await getAllCustomers();
      let _trows = [];
      if(data.message!=="No records found in database!")
      for (const [index, value] of data.entries()) {
        _trows.push(value);
      }
      setRows(_trows);        
    };

    useEffect(()=>{
        
      Userdata();
      
    },[]);
    if(isAuth)
    {
        const tableData = {columns,data:rows}
        return (
            <div className="wrapper">                    
                <Header />
                <Sidebar />
                <div className="content-wrapper" style={{minHeight: '960px'}}>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="card" id="datatable-customers">
                    <div className="card-body">
                              <DataTableExtensions {...tableData} >
                                <DataTable
                                    title="Registered Customers"
                                    columns={columns}
                                    data={rows}
                                    striped={true}
                                    responsive={true}
                                    pagination={true}
                                    
                                />
                              </DataTableExtensions>
                                </div>
                            </div>
                            {_viewUpdateUser}
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        ); 
    }
    // Showing Login Or Register Page According to the condition
    else if(showLogin){
        
        return <Login/>;
    }
    else{
        return <Register/>;
    }
  }
  
export default Listallcustomers;