import React, { useContext } from 'react';
import * as FaIcons from "react-icons/fa";
import { Link } from 'react-router-dom';
import {MyContext} from '../contexts/MyContext'
import swal from 'sweetalert2';
import Login from './../pages/Login'
import Register from './../pages/Register'

function Header (){
    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth ,showLogin} = rootState;

    const developerDetails=()=>{
        swal.fire({
            titleText: 'NEED HELP RELATED TO SOFTWARE',
            text: 'Call at +923078488903',
            icon: 'question',
            iconColor:'red',
            confirmButtonText: 'OK',
            footer:'Design & Developed by Danyal Rashid'
          });
    }   
        if(isAuth){
            return (
                    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                        
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link className="nav-link" data-widget="pushmenu" to="#" role="button"><FaIcons.FaBars /></Link>
                            </li>
                            <li className="nav-item d-none d-sm-inline-block">
                                <Link to="/home" className="nav-link">Home</Link>
                            </li>
                            <li className="nav-item d-none d-sm-inline-block">
                                <Link to="" onClick={developerDetails} className="nav-link">Contact</Link>
                            </li>
                        </ul>

                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link className="nav-link" data-widget="control-sidebar" data-slide="true" to="#" role="button" onClick={logoutUser}>
                                    <FaIcons.FaSignOutAlt />
                                </Link>
                            </li>
                        </ul>

                        
                    </nav>
            )
        }else if(showLogin){
            return <Login/>;
        }
        else{
            return <Register/>;
        }
}


export default Header;