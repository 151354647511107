import React, { useContext, useState, useEffect } from 'react';
import { MyContext } from '../contexts/MyContext'
import Login from './Login'
import Register from './Register'
import Header from './../components/Header';
import Footer from './../components/Footer';
import Sidebar from './../components/Sidebar';
import DataTable from 'react-data-table-component';
import './../assets/invoicePrint.css';
import Addnewtransferinstallment from './Addnewtransferinstallment';
import SingleTransferRecipt from './SingleTransferRecipt';
import MUIDataTable from "mui-datatables";
import swal from 'sweetalert2';
import Updatetransferinstallment from './Updatetransferinstallment';
import Updatetransfer from './Updatetransfer';
function Listallfiletransfers(props) {

  const { rootState, getalltransfers, getinstallmentsbytransferid,DeleteTransferInstallment,DeleteTransfer } = useContext(MyContext);
  const { isAuth, showLogin } = rootState;
  let [_ReciptData, SetReciptData] = useState([]);
  let [_viewInstallmentsForModal, setViewForModal] = useState([]);
  let [_AddInstallmentsForModal, setAddForModal] = useState([]);
  let [rows, setRows] = useState([]);
  let [_UpdateSaleMode,setUpdate]=useState([]);
  let [_InstallmentUpdateSaleMode,setInstallmentUpdate]=useState([]);

  const Userdata = async () => {
    const data = await getalltransfers();
    
    let _trows = [];
    if(data.message!=="No records found in database!")
    for (const [index, value] of data.entries()) {
      _trows.push(value);
    }
    setRows(_trows);
  };

  const updateInstallment=(row)=>{
    setInstallmentUpdate(<Updatetransferinstallment id={row.id} amount={row.amount} transferid={row.transferid} date={row.date} nextDue={row.nextDue} type={row.type} paidVia={row.paidVia} installmentNumber={row.installmentNumber} reciptNumber={row.reciptNumber} />);
  }

  const deleteti=async(id)=>{
   await DeleteTransferInstallment(id);
   setInstallmentUpdate("");
    SetReciptData("");
    setAddForModal("");   
    SetReciptData("");
    setViewForModal("");
  }

  useEffect(()=>{
        
    Userdata();
  },[]);

  const UpdateSaleItem=(id)=>{
    setUpdate("");
    setInstallmentUpdate("");
    setAddForModal("");   
    SetReciptData("");
    setViewForModal("");
    setUpdate(<Updatetransfer id={id} />);
  }

  const DeleteSal=async(id)=>{
    await DeleteTransfer(id);
    
    swal.fire({
      title: 'Success!',
      text: "Deleted!",
      icon: 'success',
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true
    });
    window.location.reload();
  }
    const columns = [
      {
        label: 'ID',
        name: 'id',
        options: {
          display: false,
        }
      },
      {
        label: 'File Number',
        name: 'fileNumber'
      },
      {
        label: 'Property Type',
        name: 'propertyType'
      },
      {
        label: 'Sales Manager',
        name: 'salesManager'
      },
      {
        label: 'Transfered From',
        name: 'prev_cust_name'
      },
      {
        label: 'Transfered to',
        name: 'custName'
      },
      {
        label: 'Transfer Fee',
        name: 'transfer_fee'
      },
      {
        label: 'Plot Size',
        name: 'plotSize'
      },
      {
        label: 'Plot Location',
        name: 'plot'
      },
      {
        label: 'Total Sale Price',
        name: 'salePrice'
  
      },
      {
        label: 'Transfer Date',
        name: 'saledate'
  
      },
      {
        label: 'Payment Period',
        name: 'paymentPeriod'
  
      },
      {
        label: 'Installment Type',
        name: 'installmentType',
        options: {
          display: false,
        }
  
      },
      {
        label:'Clearance',
        name:'clear',
        options: {
          display: false,
        }
      },
      {
        label:'Installments Paid',
        name:'paidInstallments',
        options: {
          display: false,
        }
      },
      {
        name: "Action",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div  style={{display:"inline-grid"}}>
              <button onClick={() => viewInstallments(tableMeta.rowData[0]) } className="btn btn-outline-primary btn-sm">
                View Installments
              </button> 
              
            <button onClick={() => UpdateSaleItem(tableMeta.rowData[0]) } className="btn btn-outline-primary btn-sm">
              EDIT
            </button>
              {tableMeta.rowData[13]==='No'?<button onClick={() => addInstallments(tableMeta.rowData[0]) } className="btn btn-outline-success btn-sm">Add Installments
              </button>:null}
              
            {parseInt(tableMeta.rowData[14])===0?<button onClick={() => DeleteSal(tableMeta.rowData[0]) } className="btn btn-outline-danger btn-sm">Delete
            </button>:null}
              </div>
            );
          }
        }
      }
    ];

  //#region View Installments 
  

  const viewInstallments = async (id) => {
    setInstallmentUpdate("");
    SetReciptData("");
    setAddForModal("");
    setUpdate("");
    const data = await getinstallmentsbytransferid(id);
    const viewColumns = [      
    {
      name: 'Invoice ID',
      selector: 'invoiceId',
      sortable: true
    },
      {
        name: 'Customer Name',
        selector: 'custName',
        sortable: true
      },

      {
        name: 'File Number',
        selector: 'fileNum',
        sortable: true
      },
      
      {
        name: 'Installment Number',
        selector: 'installmentNumber',
        sortable: true
      },
      {
        name: 'Payment Date',
        selector: 'date',
        sortable: true
      },
      {
        name: 'Next Due On',
        selector: 'nextDue',
        sortable: true
      },
      {
        name: 'Installment Type',
        selector: 'type',
        sortable: true
      },
      {
        name: 'Amount',
        selector: 'amount',
        sortable: true
      },
      {
        name: 'Paid Via',
        selector: 'paidVia',
        sortable: true
      },
      {
        name: 'Action',
        sortable: false,
        cell: (row2) => 
        <span style={{display:"inline-grid"}}>
          <button onClick={() => printRecipt(row2)} id={row2.id} name="view" className="btn btn-outline-success btn-sm">View Recipt</button>
          <button onClick={() => updateInstallment(row2)} id={row2.id} name="view" className="btn btn-outline-primary btn-sm">EDIT</button>
          <button onClick={() => deleteti(row2.id)} id={row2.id} name="view" className="btn btn-outline-danger btn-sm">DELETE</button>
        </span>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      }
    ];

    setViewForModal(
    <div className="card  no-print">
      <div className="card-body">
        <DataTable
          title="Installments"
          columns={viewColumns}
          data={data}
          striped={true}
          responsive={true}
          pagination={true}
        />
      </div>
    </div>);

  }
  //#endregion 
 

  //#region Add Installments

  

  const addInstallments = (id) => { 
    setAddForModal("");   
    SetReciptData("");
    setInstallmentUpdate("");
    setViewForModal("");
    setUpdate("");
    setAddForModal(()=><Addnewtransferinstallment id={id} />);
  }

  //#endregion 

  //#region Recipt Data


  const printRecipt = (value) => {
    setAddForModal("");
    setInstallmentUpdate("");
    setViewForModal("");
    setUpdate("");
    SetReciptData(()=><SingleTransferRecipt installmentid={value.id} transferid={value.transferid} />);
  }
  //#endregion

  const options = {
    selectableRowsHideCheckboxes:true,
    searchOpen:true,
    filter: true,
    filterType: 'dropdown',
    responsive:'standard',
    selectableRowsHeader:false,
    rowsPerPageOptions:[10,15,20]
  };

  
  if (isAuth) {
    return (
      <div className="wrapper">
        <Header />
        <Sidebar />

        <div className="content-wrapper" style={{ minHeight: '960px' }}>
          <section className="content">
            <div className="container-fluid">
              <MUIDataTable 
                    title={"All Sales Record"}
                    columns={columns}
                    data={rows} 
                    options={options}

                    className="no-print"
                    />
              <br/><br/>
              {_viewInstallmentsForModal}
              {_AddInstallmentsForModal}
              {_ReciptData}
              {_InstallmentUpdateSaleMode}
              {_UpdateSaleMode}
            </div>
          </section>
        </div>
        <Footer />

      </div>
    );
  }
  // Showing Login Or Register Page According to the condition
  else if (showLogin) {

    return <Login />;
  }
  else {
    return <Register />;
  }
}

export default Listallfiletransfers;