import React, {useContext,useState,useEffect} from 'react'
import {MyContext} from '../contexts/MyContext'
import swal from 'sweetalert2';
import Login from './Login';
import Register from './Register';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';

function Sendsmstouser(){
    const {rootState,getAllCustomers,getSmsApi,sendSms} = useContext(MyContext);
    
    let [users,setUsers] = useState([]);
    
    const {isAuth,showLogin} = rootState;
    const initialState = {
        userInfo:{
            username:'',
            pass:'',
            brand:''
        },
        sendto:{
            number:'',
            message:''
        },
        errorMsg:'',
        successMsg:'',
    }
    let [state,setState] = useState(initialState);
   

    const getSms=async()=>{
        const _s=await getSmsApi();
        setState({
            ...state,
            userInfo:{
                username:_s[0].username,
                pass:_s[0].password,
                brand:_s[0].brandname
            }
        });
    }
    
    const getAllUsers = async () =>{
        const usersData= await getAllCustomers();
        if(usersData.message!=="No records found in database!")
        setUsers(usersData);
    }
    
useEffect(()=>{
    getAllUsers();
    getSms();
    
},[]);
if(isAuth)
{

        // On Submit the Registration Form
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await sendSms(state.userInfo,state.sendto);

        console.log(data);
        if(data){
            setState({
                successMsg:data.message,
                        
                sendto:{
                    number:'',
                    message:''
                }
            });
            swal.fire({
                title: 'Success!',
                text: data.message,
                icon: 'success',
                showConfirmButton: false,
                toast:true,
                position:'top-right',
                timer: 2000,
                timerProgressBar: true
              });
        }
        else{
            setState({
                ...state,
                successMsg:'',
                errorMsg:data.message
            });
            swal.fire({
                text: data.message,
                icon: 'success',
                confirmButtonText: 'OK'
              });
        }
    }
    const _user = users.map((item,i)=>(<option value={item.phone} key={i}>{item.name}</option>));

    // On change the Input Value (name, email, password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            sendto:{
                ...state.sendto,
                [e.target.name]:e.target.value
            }
        });
    }
    
    return(
        
    <div className="wrapper">                    
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{minHeight: '960px'}}>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="login-box-msg">Set sms api</h4>

                            <form  onSubmit={submitForm} >
                            <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="number" className="label">Select Customer: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        
                                        <select  name="number" className="form-control" required value={state.sendto.number} onChange={onChangeValue}>
                                            <option value="">...</option>
                                            {_user}
                                        </select>
                                    
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-user"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="pass" className="label">Message: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="message" className="form-control" type="text" required value={state.sendto.message} onChange={onChangeValue} placeholder="Type Message Here..."/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-user-circle"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col-12">
                                        <button type="submit" className="btn btn-primary btn-block">Send SMS</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <Footer />
    </div>
    );
}
// Showing Login Or Register Page According to the condition
else if(showLogin){
    
    return <Login/>;
}
else{
    return <Register/>;
}
}

export default Sendsmstouser;