import React, { useContext,useState,useEffect } from 'react';
import {MyContext} from '../contexts/MyContext'
import Login from './Login'
import Register from './Register'
import Header from './../components/Header';
import Footer from './../components/Footer';
import Sidebar from './../components/Sidebar';
import DataTable from 'react-data-table-component';


function Listallusers (props) {
    
    
    const {rootState,getAllUsers} = useContext(MyContext);
    const {isAuth,showLogin} = rootState;
    let [rows, setRows] = useState([]);
    
    const columns = [
      {
        name: 'Username',
        selector: 'username',
        sortable: true
      },
      {
        name: 'Firstname',
        selector: 'fname',
        sortable: true
      },
      {
        name: 'Lastname',
        selector: 'lname',
        sortable: true
      },
      {
        name: 'Phone',
        selector: 'phone',
        sortable: true
        
      },
      {
        name: 'IsActive',
        selector: 'isactive',
        sortable: true
        
      },
      {
        name: 'Date of Joining',
        selector: 'joindate',
        sortable: true
        
      },
      {
        name: 'Role',
        selector: 'role',
        sortable: true
        
      }
    ];
    
    const Userdata = async()=> {
      const data =  await getAllUsers();
      let _trows = [];
      if(data.message!=="No records found in database!")
      for (const [index, value] of data.entries()) {
        _trows.push(value);
      }
    
      setRows(_trows);       
    };

    useEffect(()=>{
        
      Userdata();
    },[]);
    
  if(isAuth)
  {
        return (
            <div className="wrapper">                    
                <Header />
                <Sidebar />
                <div className="content-wrapper" style={{minHeight: '960px'}}>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-body">
                                <DataTable
                                    title="Registered Users"
                                    columns={columns}
                                    data={rows}
                                    striped={true}
                                    responsive={true}
                                    pagination={true}
                                    
                                />
                                  
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        ); 
    }
    // Showing Login Or Register Page According to the condition
    else if(showLogin){
        
        return <Login/>;
    }
    else{
        return <Register/>;
    }
  }
  
export default Listallusers;