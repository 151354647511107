import React, {useContext,useState,useEffect} from 'react'
import {MyContext} from '../contexts/MyContext'
import swal from 'sweetalert2';
import Login from './Login';
import Register from './Register';
import Header from './../components/Header';
import Footer from './../components/Footer';
import Sidebar from './../components/Sidebar';

function Addnewsale(){
    const {rootState,addnewsale,getAllCustomers} = useContext(MyContext);
    const {isAuth,showLogin} = rootState;
    const initialState = {
        sale:{
            plot :'',
            customerID :'',
            salePrice :'',
            saledate :'',
            paymentPeriod :'',
            installmentType :'',
            fileNumber :'',
            plotSize :'',
            propertyType:'',
            salesManager:''
        },
        errorMsg:'',
        successMsg:'',
    }
    const [state,setState] = useState(initialState);
    let [users,setUsers] = useState([]);

    
    const getAllUsers = async () =>{
        const usersData= await getAllCustomers();
        if(usersData.message!=="No records found in database!")
        setUsers(usersData);
    }
    useEffect(()=>{
        
    getAllUsers();   
      },[]);
    if(isAuth)
    { 
    // On Submit the Registration Form
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await addnewsale(state.sale);
        if(data.success){
            setState({
                ...initialState,
                successMsg:data.message,
            });
            swal.fire({
                title: 'Success!',
                text: data.message,
                icon: 'success',
                showConfirmButton: false,
                toast:true,
                position:'top-right',
                timer: 2000,
                timerProgressBar: true
              });
        }
        else{
            console.log(data);
            setState({
                ...state
            });
            swal.fire({
                title: 'Error!',
                text: data.message,
                icon: 'error',
                confirmButtonText: 'Retry'
              });
        }
    }

const _user = users.map((item,i)=>(<option value={item.id}>{item.name}</option>));

    // On change the Input Value (name, email, password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            sale:{
                ...state.sale,
                [e.target.name]:e.target.value,
                errorMsg:''
            }
        });
    }
    
        
    return(
        
    <div className="wrapper">                    
        <Header />
        <Sidebar />
        <div className="content-wrapper" style={{minHeight: '960px'}}>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="login-box-msg">Add new sale of Plot</h4>

                            <form  onSubmit={submitForm} >
                                
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="customerID" className="label">Select Customer: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        
                                        <select  name="customerID" className="form-control" required value={state.sale.customerID} onChange={onChangeValue}>
                                            <option value="">...</option>
                                            {_user}
                                        </select>
                                    
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-user"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="plot" className="label">Plot Location: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="plot"  className="form-control" required type="text" value={state.sale.plot} onChange={onChangeValue} placeholder="Plot Location"/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-map-marker"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="plotSize" className="label">Plot Size: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="plotSize" className="form-control" required type="text" value={state.sale.plotSize} onChange={onChangeValue} placeholder="Plot Size"/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-chart-line"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="salePrice" className="label">Plot Total Price: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="salePrice" className="form-control" required type="text" value={state.sale.salePrice} onChange={onChangeValue} placeholder="Plot Price"/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-money-check"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="saledate" className="label">Sale Date: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="saledate" className="form-control" placeholder="yyyy-mm-dd" min="1997-01-01" max="2099-12-31" required type="date" value={state.sale.saledate} onChange={onChangeValue}/>
                                        
                                    </div>
                                </div>
                                
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="propertyType" className="label">Property Type: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <select name="propertyType" className="form-control" required value={state.sale.propertyType} onChange={onChangeValue}>
                                            <option>...</option>
                                            <option value="Commercial">Commercial</option>
                                            <option value="Resedential">Resedential</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="salesManager" className="label">Sales Manager: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="salesManager" className="form-control" required type="text" value={state.sale.salesManager} onChange={onChangeValue} placeholder="Sales Manager"/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-clock"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="paymentPeriod" className="label">Payment Period: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="paymentPeriod" className="form-control" required type="text" value={state.sale.paymentPeriod} onChange={onChangeValue} placeholder="Payment Period"/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-clock"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="installmentType" className="label">Installment Type: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="installmentType" className="form-control" required type="text" value={state.sale.installmentType} onChange={onChangeValue} placeholder="Monthly/Yearly"/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-user-circle"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="fileNumber" className="label">File Number: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="fileNumber" className="form-control" required type="text" value={state.sale.fileNumber} onChange={onChangeValue} placeholder="FCC123456"/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-file"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col-12">
                                        <button type="submit" className="btn btn-primary btn-block">Add Sale</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <Footer />
    </div>
    );
}
// Showing Login Or Register Page According to the condition
else if(showLogin){
    
    return <Login/>;
}
else{
    return <Register/>;
}
}

export default Addnewsale;