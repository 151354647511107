import React, {useContext,useState,useEffect} from 'react'
import {MyContext} from '../contexts/MyContext'
import swal from 'sweetalert2';

function Updatecustomer(props){
    const {updatecustomer,getcustomerbyid} = useContext(MyContext);
    const initialState = {
        userInfo:{
            name:'',
            phone:'',
            email:'',
            cnic:'',
            id:''
        },
        errorMsg:'',
        successMsg:'',
    }
    const [state,setState] = useState(initialState);


    const GetCustomer=async(id)=>{
        const data= await getcustomerbyid(id);
        console.log(data); 
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                name:data[0].name,
                phone:data[0].phone,
                email:data[0].email,
                cnic:data[0].cnic,
                id:props.id
            }
        });
    }

    
    useEffect(()=>{
        GetCustomer(props.id);
    },[]);
    // On Submit the Registration Form
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await updatecustomer(state.userInfo);
        if(data.success){
            setState({
                ...initialState,
                successMsg:data.message,
            });
            swal.fire({
                title: 'Success!',
                text: data.message,
                icon: 'success',
                showConfirmButton: false,
                toast:true,
                position:'top-right',
                timer: 2000,
                timerProgressBar: true
              });
            window.location.reload();
        }
        else{
            setState({
                ...state,
                successMsg:'',
                errorMsg:data.message
            });
            swal.fire({
                title: 'Error!',
                text: data.message,
                icon: 'error',
                confirmButtonText: 'Retry'
              });
        }
    }

    // On change the Input Value (name, email, password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value,
                errorMsg:''
            }
        });
    }
    
    return(
        
                    <div className="card">
                        <div className="card-body">
                            <h4 className="login-box-msg">Update Customer</h4>

                            <form  onSubmit={submitForm} >
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="name" className="label">Customer Name: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="name"  className="form-control" required type="text" value={state.userInfo.name??''} onChange={onChangeValue} placeholder="Customer Name"/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-user"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="name" className="label">Customer Email: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="email" className="form-control" type="email" value={state.userInfo.email??''} onChange={onChangeValue} placeholder="example@example.com"/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-user-circle"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="name" className="label">Customer Phone Number: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="phone" className="form-control" type="phone" value={state.userInfo.phone??''} onChange={onChangeValue} placeholder="923000000000"/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-phone"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row p-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="cnic" className="label">Customer CNIC: </label>
                                    </div>
                                    <div className="input-group col-sm-9">
                                        <input name="cnic" className="form-control" required type="text" value={state.userInfo.cnic??''} onChange={onChangeValue} placeholder="CNIC" pattern="[0-9]+" maxLength="13" minLength="13" />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-phone"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col-12">
                                        <button type="submit" className="btn btn-primary btn-block">Update Customer</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
    );
}


export default Updatecustomer;